import { isFuture, format, isToday, parseISO } from "date-fns";
export const enabledFutureDatesFn = (
  dates: string[] = [],
  currentDay: boolean = true
) => {
  const enabledDate = (dateIsoString: string) => {
    if (dates.includes(format(parseISO(dateIsoString), "yyyy-MM-dd"))) {
      return false;
    }

    if (currentDay) {
      return (
        isToday(parseISO(dateIsoString)) || isFuture(parseISO(dateIsoString))
      );
    }

    if (!currentDay) {
      return isFuture(parseISO(dateIsoString));
    }
  };

  return enabledDate;
};
