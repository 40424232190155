import { Injectable } from "@angular/core";
import { Observable, catchError, BehaviorSubject, tap } from "rxjs";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ShopScheduleApiService {
  coreEndPoint = environment.coreEndPoint;
  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  constructor(private http: HttpHelperService) {}

  set loading(state: boolean) {
    this.#loading$.next(state);
  }

  getScheduleList<T>(id: number): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `admin/shop/${id}/schedule`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => (this.loading = false))
    );
  }

  update<T>(id: number, body: any): Observable<IHttpResponse<T>> {
    const path = `admin/shop/${id}/schedule`;
    return this.http.put(path, body).pipe(catchError(this.http.catch()));
  }

  addExceptionDate<T>(id: number, body: any): Observable<IHttpResponse<T>> {
    const path = `admin/shop/${id}/schedule/exception`;
    return this.http.post(path, body).pipe(catchError(this.http.catch()));
  }

  deleteExceptionDate<T>(
    id: number,
    ids: number[]
  ): Observable<IHttpResponse<T>> {
    let path = `admin/shop/${id}/schedule/exception?`;
    path += ids.map((recordId) => `recordIds[]=${recordId}`).join("&");
    return this.http.delete(path).pipe(catchError(this.http.catch()));
  }
}
