import { optionFn } from "@helpers/common/functions";
import { ISelectOption } from "@helpers/common/interfaces";

export const apiStatuses = {
  normal: "正常",
  abnormal: "异常",
  maintenance: "维护中",
  outage: "停运",
} as const;

export const apiStatusOptions: ISelectOption<string>[] = optionFn(apiStatuses);
