import { IMenuProduct } from "../interfaces";

export const menuProductSetAddProduct = (set: Set<number>, products: IMenuProduct[]): Set<number> => {
    products.forEach(p => {
        if (!set.has(p.id)) {
            set.add(p.id);
        }
    });
    return set;
};
