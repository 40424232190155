import { Injectable, signal } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";
import { shareReplay, switchMap, catchError, tap, map } from "rxjs/operators";
import { StatusCode } from "@helpers/common/interfaces";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { environment } from "src/environments/environment";
import { ShopApiService } from "../shop";
import { menuProductSetAddProduct } from "./functions";
import {
  IMenuProduct,
  TMenuCriteria,
  TMenuProductCategoryDTO,
  TMenuProductContentDTO,
} from "./interfaces";
import * as qs from "qs";
interface IProductList {
  data: IMenuProduct[];
  synced: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MenuProductApiService {
  coreEndPoint = environment.coreEndPoint;

  #product$ = new BehaviorSubject<IMenuProduct | null>(null);
  product$ = this.#product$.asObservable();

  productLoading$ = signal(false);

  list$ = signal<IProductList>({
    data: [],
    synced: false,
  });

  #listSet$ = new BehaviorSubject<Set<number>>(new Set());
  listSet$ = this.#listSet$.asObservable();

  #listCount$ = new BehaviorSubject<number>(0);
  listCount$ = this.#listCount$.asObservable();

  #listLoading$ = new BehaviorSubject<boolean>(false);
  listLoading$ = this.#listLoading$.asObservable();
  private shop$ = this.shopService.shop$;
  constructor(
    private http: HttpHelperService,
    private shopService: ShopApiService
  ) {}

  set product(value: IMenuProduct | null) {
    this.#product$.next(value);
  }

  set listSet(value: Set<number>) {
    this.#listSet$.next(value);
  }

  set listCount(value: number) {
    this.#listCount$.next(value);
  }

  set listLoading(value: boolean) {
    this.#listLoading$.next(value);
  }

  initAll(): void {
    this.resetList();
    this.product = null;
    this.listCount = 0;
    this.listSet = new Set();
  }

  resetList(): void {
    this.list$.set({ data: [], synced: false });
  }

  getList<T>(
    menuId: number,
    criteria: TMenuCriteria
  ): Observable<IProductList> {
    const list = this.list$();
    if (list.synced) {
      return of(list);
    }

    return this.getListFromServer<{
      count: number;
      products: IMenuProduct[];
    }>(menuId, criteria).pipe(map((res) => res.data.products));
  }

  getListFromServer<T>(
    menuId: number,
    criteria: TMenuCriteria
  ): Observable<IHttpResponse<T>> {
    const queries = qs.stringify(criteria);
    this.listLoading = true;
    const path = `admin/menu/${menuId}/products?${queries}`;
    return this.http.get(path).pipe(
      tap(() => (this.listLoading = false)),
      tap((res) => {
        const el = menuProductSetAddProduct(
          this.#listSet$.value,
          res.data?.products
        );
        this.listSet = el;
        const data = res.data?.products ?? [];
        this.list$.update((list) => {
          list.data = list.data.filter(
            (item) => !data.find((d) => d.id === item.id)
          );
          list.data = [...list.data, ...res.data?.products];

          return list;
        });

        this.listCount = res.data?.count;
      })
    );
  }

  searchListFromServer<T>(
    menuId: number,
    criteria: string = ""
  ): Observable<IHttpResponse<T>> {
    this.listLoading = true;
    const path = `admin/menu/${menuId}/products?${criteria}`;
    return this.http.get(path).pipe(tap(() => (this.listLoading = false)));
  }

  getListFromCacheByCatId<T>(
    id: number = 1,
    body = {}
  ): Observable<IHttpResponse<T>> {
    return this.getListFromServerByCatId<T>(id).pipe(shareReplay());
  }

  getListFromServerByCatId<T>(
    id: number = 1,
    body = {}
  ): Observable<IHttpResponse<T>> {
    const path = `api/shop/${this.shop$()?.id}/menu/products/${id}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap((res) => {
        const { data } = res;
        const list = data?.products ?? [];
        // this.l$ist = list;
        this.list$.set(list);
      })
    );
  }

  getRecordByProductId(
    mid: number,
    pid: number,
    token?: string
  ): Observable<IMenuProduct | null> {
    const list = this.list$();
    const idx = list.data.findIndex((p) => p.id === pid);
    const product = idx >= 0 ? list[idx] : null;

    if (product) {
      if (!token) {
        return of(product);
      }

      if (token && product.token === token) {
        return of(product);
      }
    }

    return this.getFromServerByProductId<{ product: IMenuProduct }>(
      mid,
      pid
    ).pipe(
      map((res) => res?.data?.product),
      tap((item) => {
        if (idx >= 0) {
          list[idx] = item;
          // this.list = list;
          this.list$.set(list);
        }
      })
    );
  }

  getFromServerByProductId<T>(
    mid: number,
    pid: number
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  addProduct<T>(
    mid: number,
    body: { categories?: number[]; data: TMenuProductContentDTO }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/add`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateProductContent<T>(
    mid: number,
    pid: number,
    body: { data: TMenuProductContentDTO }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/content`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateProductTax<T>(
    mid: number,
    pid: number,
    body: { data: TMenuProductContentDTO }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/tax`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateProductStatus<T>(
    mid: number,
    pid: number,
    body: {
      status: StatusCode;
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/status`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateStockStatus<T>(
    mid: number,
    pid: number,
    body: {
      status: StatusCode;
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/stock`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateStockStatusBatch<T>(
    mid: number,
    body: {
      status: StatusCode;
      products: number[];
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/products/stock`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateDiscountableStatus<T>(
    mid: number,
    pid: number,
    body: {
      status: StatusCode;
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/discountable`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  updateCategory<T>(
    mid: number,
    pid: number,
    body: TMenuProductCategoryDTO
  ): Observable<IHttpResponse<T>> {
    const path = `admin/menu/${mid}/product/${pid}/related/categories`;
    return this.http.put(path, body).pipe(catchError(this.http.catch()));
  }

  addCategory<T>(
    mid: number,
    pid: number,
    body: {
      categories: number[];
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/related/categories/add`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  removeCategory<T>(
    mid: number,
    pid: number,
    body: {
      categories: number[];
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/related/categories/remove`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }

  addRelatedProduct<T>(
    mid: number,
    pid: number,
    body: {
      products: { upc: string; count: number }[];
    }
  ): Observable<IHttpResponse<T>> {
    this.productLoading$.set(true);
    const path = `admin/menu/${mid}/product/${pid}/related/products/add`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => this.productLoading$.set(false))
    );
  }
}
