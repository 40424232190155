import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
  signal,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { IonicModule, Platform } from "@ionic/angular";
import md5 from "md5";
import { NavHelperService } from "@helpers/nav";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TNavItem } from "@layout/sidebar/sidebar-nav";

@Component({
  selector: "sidebar-nav-item",
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule],
  template: `
    <!-- Current Level with conditions -->
    @if (item.subItems && item.subItems.length && isMobile$()) {
    <ion-item
      [button]="true"
      lines="none"
      [detail]="level > 0 ? true : false"
      [id]="id"
    >
      <ng-template [ngTemplateOutlet]="currentLevel" />
      <ion-icon color="medium" name="add-circle-outline"></ion-icon>
    </ion-item>

    } @else {
    <ion-menu-toggle auto-hide="false">
      <ion-item
        [routerLink]="[item.url]"
        routerDirection="root"
        routerLinkActive="selected"
        lines="none"
        [detail]="level > 0 ? true : false"
        [id]="id"
      >
        <ng-template [ngTemplateOutlet]="currentLevel" />
        @if (item.subItems && item.subItems.length ) {
        <ion-icon
          slot="end"
          color="medium"
          [name]="isActive$() ? 'remove-circle-outline' : 'add-circle-outline'"
        ></ion-icon>
        }
      </ion-item>
    </ion-menu-toggle>
    }
    <!-- Next Level with conditions -->
    @if (item.subItems && item.subItems.length && isMobile$()) {
    <ion-popover [trigger]="id" [dismissOnSelect]="true" size="cover">
      <ng-template>
        <ion-list inset="true">
          <ng-template [ngTemplateOutlet]="nextLevel" />
        </ion-list>
      </ng-template>
    </ion-popover>
    } @if (isActive$() && item.subItems && item.subItems.length ) {
    <ion-list inset="true">
      <ng-template [ngTemplateOutlet]="nextLevel" />
    </ion-list>
    }

    <!-- Current Level template -->
    <ng-template #currentLevel>
      @if (item.source === 'bootstrap') {
      <span class="mr-6 text-base text-gray-300">
        <i [className]="item.icon"></i>
      </span>
      } @else {
      <ion-icon
        slot="start"
        color="medium"
        [ios]="item.icon + '-outline'"
        [md]="item.icon + '-sharp'"
      ></ion-icon>
      }
      <ion-label>{{ item.title }}</ion-label>
    </ng-template>

    <!-- Next Level template -->
    <ng-template #nextLevel>
      @for (subItem of item.subItems; track $index) {
      <sidebar-nav-item [item]="subItem" [level]="level + 1" />
      }
    </ng-template>
  `,
  styleUrls: ["./sidebar-nav-item.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavItemComponent implements OnInit {
  @Input() item: TNavItem;
  @Input() level = 0;
  id = "";
  isActive$ = signal(false);
  isMobile$ = signal(false);

  private navHelper = inject(NavHelperService);
  private destroyRef = inject(DestroyRef);
  private platForm = inject(Platform);

  ngOnInit(): void {
    this.id = md5(JSON.stringify(this.item ?? ""));
    this.watch();

    this.platForm.ready().then(() => {
      this.isMobile$.set(this.platForm.is("mobile"));
    });
  }

  private watch(): void {
    this.navHelper
      .watchUrl()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (url) => {
          if (this.item.url === url || url.startsWith(this.item.url)) {
            this.isActive$.set(true);
          } else {
            this.isActive$.set(false);
          }
        },
      });
  }
}
0;
