
export const printModes = {
    text: {
        name: 'Text String',
        code: 'TEXT'
    },
    raster: {
        name: 'Raster Mode',
        code: 'RASTER'
    },
    base64: {
        name: 'Base64 Image',
        code: 'BASE64'
    },
    cli: {
        name: 'EscPos Command Line',
        code: 'COMMAND_LINE'
    },

} as const;


