import { ISelectOption } from "../interfaces";

export const optionFn = <T>(state: any): ISelectOption<T>[] => {
    const options: ISelectOption<T>[] = [];

    for (const key in state) {
        if (Object.prototype.hasOwnProperty.call(state, key)) {
            const code = key as any;
            const element = state[code];
            if (typeof element === 'string') {
                options.push({
                    label: element,
                    value: code
                });
            } else {

                options.push({
                    label: element.text,
                    value: element.query
                });
            }
        }
    }

    return options;
};
