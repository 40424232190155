import { inject, Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { AuthCoreService } from "../auth";
import { Member } from "../member/models";
import { IApiStatus } from "./interfaces";
import { ApiStatusModel } from "./models";

@Injectable({
  providedIn: "root",
})
export class ApiStatusService {
  #coreApiStatus$ = new BehaviorSubject<ApiStatusModel>(
    new ApiStatusModel(null)
  );

  coreApiStatus$ = this.#coreApiStatus$.asObservable();

  status = signal<ApiStatusModel>(new ApiStatusModel(null));

  constructor(
    private http: HttpHelperService,
    private authService: AuthCoreService
  ) {}

  set coreApiStatus(value: ApiStatusModel) {
    this.#coreApiStatus$.next(value);
  }

  coreApiStatusGet(): Observable<ApiStatusModel> {
    const path = `admin/status`;

    return this.http.get(path).pipe(
      catchError(this.http.catch()),

      map((res: IHttpResponse<any>) => {
        const record = (res.data as IApiStatus) ?? null;
        const status = new ApiStatusModel(record);
        const sessionToken = res?.data?.sessionToken;
        if (sessionToken) {
          this.authService.accessToken = sessionToken;
        }
        const member = res?.data?.member
          ? new Member(res?.data?.member)
          : new Member();
        this.authService.authUser$.set(member);

        this.coreApiStatus = status;
        this.status.set(status);
        return status;
      })
    );
  }
}
