import { PrinterVendor } from "../interfaces";

export const printerVendors = {
  browser: {
    name: "Browser",
    code: "BROWSER",
  },
  epson: {
    name: "Epson",
    code: "EPSON",
  },
  star: {
    name: "Star",
    code: "STAR",
  },
  sunmi: {
    name: "Sunmi",
    code: "SUNMI",
  },
  bixolon: {
    name: "Bixolon",
    code: "BIXOLON",
  },
  escpos: {
    name: "EscPos",
    code: "ESCPOS",
  },
} as const;

export const PrinterVendorList: PrinterVendor[] = [
  printerVendors.browser,
  printerVendors.sunmi,
  printerVendors.star,
  printerVendors.escpos,
  printerVendors.epson,
  printerVendors.bixolon,
];
