export const pinterStarEmulations = {
    /** mPOP, SM-L200, SM-L300, SM-S210i, SM-S220i, SM-S230i, SM-T300i/T300, SM-T400i */
    starPRNT: {
        name: 'StarPRNT',
        code: 'StarPRNT'
    },
    /** SM-L200, SM-L300 */
    starPRNTL: {
        name: 'StarPRNTL',
        code: 'StarPRNTL'
    },
    /** FVP10, TSP650II, TSP700II, TSP800II */
    starLine: {
        name: 'StarLine',
        code: 'StarLine'
    },
    /** TSP100 */
    starGraphic: {
        name: 'StarGraphic',
        code: 'StarGraphic'
    },
    /** BSC10 */
    escPos: {
        name: 'EscPos',
        code: 'EscPos'
    },
    /** SM-S210i, SM-S220i, SM-S230i, SM-T300i/T300, SM-T400i */
    escPosMobile: {
        name: 'EscPosMobile',
        code: 'EscPosMobile'
    },
    /** SP700 */
    starDotImpact: {
        name: 'StarDotImpact',
        code: 'StarDotImpact'
    },
} as const;


