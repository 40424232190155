import { apiStatuses } from "../constants";
import { ApiStatusCode, IApiStatus } from "../interfaces";

export class ApiStatusModel {
  code: ApiStatusCode | null;
  name = "outage";
  message: string | null = null;
  rbac: any = null;

  #status = "outage";
  #name = "outage";
  #code: ApiStatusCode = "normal";

  constructor(data: IApiStatus | null) {
    this.code = data?.status ?? "outage";
    this.message = data?.message ?? null;
    // this.rbac = data?.rbac ?? null;
  }

  get status(): string {
    return this.#status;
  }

  set status(code: string) {
    const key = code as ApiStatusCode;
    this.#status = apiStatuses[key];
  }

  // get name(): string {
  //     return this.#name;
  // }
  // set name(value: string | null) {
  //     this.#name = value ?? 'Unknown';
  // }

  // get code(): ApiStatusCode {
  //     return this.#code;
  // }
  // set code(code: ApiStatusCode | null) {
  //     this.#code = code ?? 'outage';
  //     this.status = this.code;
  // }
}
