import { IMember } from "../interfaces";
import { intersection } from "lodash";
import {
  AuthorityGroup,
  AuthorityRole,
  IAuthorityRole,
} from "../../acl/interfaces";

import { format } from "date-fns/format";
export class Member {
  #data: IMember | null = null;
  private _checked = false;

  constructor(data: IMember | null = null) {
    this.#data = data;
  }

  get roleCode(): AuthorityRole[] {
    if (!this.#data) {
      return [];
    }

    return this.roles.map((role) => role.code);
  }

  get id(): string {
    return this.#data ? this.#data.id : format(new Date(), "X");
  }

  get groupCode(): AuthorityGroup[] {
    if (!this.#data) {
      return [];
    }

    return this.groups.map((group) => group.code);
  }

  get roles(): IAuthorityRole[] {
    if (!this.#data) {
      return [];
    }

    return this.#data.roles;
  }

  get groups(): any[] {
    if (!this.#data) {
      return [];
    }

    return this.#data.groups;
  }

  get createdAt(): string | null {
    return this.#data?.createdAt ?? null;
  }

  get updatedAt(): string | null {
    return this.#data?.updatedAt ?? null;
  }

  get data(): IMember | null {
    return this.#data;
  }

  get isLoggedIn(): boolean {
    return this.#data ? true : false;
  }

  get fullname(): string {
    if (!this.#data) {
      return "Guest";
    }

    return `${this.title} ${this.lastname}  ${this.firstname}`;
  }

  get firstname(): string {
    if (!this.#data) {
      return "";
    }

    return this.#data.firstName;
  }

  get lastname(): string {
    if (!this.#data) {
      return "";
    }

    return this.#data.lastName;
  }

  get title(): string {
    let title = "";
    if (!this.#data) {
      return title;
    }

    switch (this.#data.gender) {
      case "male":
        title = "Mr.";
        break;
      case "female":
        title = "Mrs";
        break;
      default:
        break;
    }

    return title;
  }

  get email(): string | null {
    return this.#data?.email ?? null;
  }

  get countryCode(): string {
    return this.#data?.countryCode ? `+${this.#data.countryCode}` : "";
  }

  get phoneNumber(): string | null {
    return this.#data?.phoneNumber
      ? `${this.countryCode} ${this.#data.phoneNumber}`
      : null;
  }

  get profile(): IMember | null {
    return this.#data;
  }

  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    this._checked = value;
  }

  hasRole = (rolesGroups: string[]) => {
    const roleCount = intersection(rolesGroups, this.roleCode);
    return roleCount.length > 0 ? true : false;
  };

  hasGroup = (groups: string[]) => {
    const count = intersection(groups, this.groupCode);
    return count.length > 0 ? true : false;
  };
}
