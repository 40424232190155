import { TNavItem } from "./nav.type";

export const navData: TNavItem[] = [
  {
    title: "WebShop Orders",
    url: "/orders",
    icon: "receipt",
  },
  {
    title: "Catalogs",
    icon: "book",
    url: "/orgs/catalogs",
  },
  {
    title: "Stores",
    icon: "storefront",
    url: "/orgs/shops",
  },
  {
    title: "Delivery",
    icon: "bag-handle",
    url: "/orgs/delivery",
  },
  {
    title: "Customers",
    icon: "people-circle",
    url: "/orgs/customers",
  },
  {
    title: "Payments",
    icon: "card",
    url: "/orgs/payments",
  },
  {
    title: "Orders",
    icon: "receipt",
    url: "/orgs/orders",
  },
  {
    title: "Media Library",
    icon: "images",
    url: "/orgs/media",
  },
  {
    title: "Blog Posts",
    icon: "document-text",
    url: "/orgs/posts",
  },
  {
    title: "Notifications",
    icon: "notifications",
    url: "/orgs/notifications",
  },

  // {
  //   title: 'My Businesses',
  //   url: '/orgs',
  //   icon: 'bi  bi-gem ',
  //   source: 'bootstrap',
  // },
  {
    title: "Settings",
    url: "/settings",
    icon: "options",
  },
];
