import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, } from 'rxjs';
import { ToastButton } from '@ionic/core';

export interface IToastOpt {
  header?: string | undefined;
  message?: string | undefined;
  position?: 'bottom' | 'middle' | 'top';
  translucent?: boolean | undefined;
  duration?: number | undefined;
  color?: string | undefined;
  buttons?: (string | ToastButton)[] | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  listener$ = new BehaviorSubject<IToastOpt | null>(null);
  private toasts: HTMLIonToastElement[] = [];
  constructor(
    private toastCtrl: ToastController
  ) {
    this.watch();
  }

  show() {
    this.toasts[0].present();
  }

  private watch() {
    // tslint:disable-next-line: deprecation
    this.listener$.subscribe({
      next: value => {
        if (value) {
          this.push(value);
        }
      }
    });
  }


  private async push(opts: IToastOpt = {}) {
    const toast = await this.toastCtrl.create({
      ...{
        duration: 1500,
        position: 'bottom'
      }, ...opts
    });

    toast.onDidDismiss().then(() => {
      this.toasts.shift();
      if (this.toasts.length > 0) {
        this.show();
      }
    });

    this.toasts.push(toast);

    if (this.toasts.length > 0) {
      this.show();
    }
  }



}
