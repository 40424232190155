import { IMenuCategory } from "../interfaces";

export const menuCategorySetAddProduct = (set: Set<number>, categories: IMenuCategory[]): Set<number> => {
    categories.forEach(c => {
        if (!set.has(c.id)) {
            set.add(c.id);
        }
    });
    return set;
};
