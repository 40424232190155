import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy, Router } from "@angular/router";
// import { provideQueryDevTools } from "@ngneat/query";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoaderFactory } from "./helpers/common/constants/i18n";
import { InitializerService, initProviderFactory } from "./api/initializer";
import { HttpRequestInterceptor } from "./helpers/http/interceptors";
import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode/ngx";
import { QuillModule } from "ngx-quill";
import * as Sentry from "@sentry/angular";
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { environment } from "src/environments/environment";
import { SidebarComponent } from "./components/layout/sidebar";

/**
 * @Configs Quill Module
 */
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button

    ["link", "image"], // link and image, video
  ],
};

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions =
  (environment.mqtt as IMqttServiceOptions) ?? {};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: "ios",
      toggleOnOffLabels: true,
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: "fr",
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot({
      modules,
      placeholder: "Starting here......",
    }),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    SidebarComponent,
  ],
  providers: [
    // environment.production ? [] : provideQueryDevTools({}),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: ErrorHandler,
    //   // Attach the Sentry ErrorHandler
    //   useValue: SentryAngular.createErrorHandler(),
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    Insomnia,
    BackgroundMode,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
