import { Injectable, computed, signal } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { StorageHelperService } from "@helpers/storage";
import { selectedMerchantNamespace } from "@helpers/storage/constants";
import { environment } from "src/environments/environment";
import { IMerchant } from "./interfaces";
import { injectQueryClient } from "@ngneat/query";

@Injectable({
  providedIn: "root",
})
export class MerchantApiService {
  coreEndPoint = environment.coreEndPoint;

  list$ = signal<IMerchant[]>([]);
  mch$ = computed(() => this.list$().find((mch) => mch.selected));
  syncing$ = signal(false);
  updating$ = signal(false);
  #client = injectQueryClient();
  constructor(
    private storageService: StorageHelperService,
    private http: HttpHelperService
  ) {}

  getMchList(): Observable<IMerchant[]> {
    if (this.list$().length) {
      return of(this.list$());
    }

    return this.initList(true);
  }

  init(): Observable<{ mch: IMerchant | null; list: IMerchant[] }> {
    return forkJoin({
      list: this.getMchList(),
      mchId: this.getMchId(),
    }).pipe(
      map((res) => {
        let mch: IMerchant | null = null;
        const { mchId, list } = res;
        if (!mchId) {
          return { mch, list };
        }

        this.list$.update((list) =>
          list.map((mch) => {
            mch.selected = mch.id === mchId ? true : false;
            return mch;
          })
        );
        mch = this.mch$();
        if (!mch) {
          this.setMchId(null);
        }
        return { mch, list };
      })
    );
  }

  selectMch(id: string | null): Observable<boolean> {
    this.updating$.set(true);
    return this.setMchId(id).pipe(
      tap((res) => this.updating$.set(false)),
      tap((res) => {
        if (res) {
          this.list$.update((list) => {
            list.map((mch) => {
              mch.selected = mch.id === id ? true : false;
              return mch;
            });
            return [...list];
          });
          this.#client.resetQueries({});
        }
      })
    );
  }

  initList(fromServer = false): Observable<IMerchant[]> {
    this.syncing$.set(true);
    const method = fromServer
      ? this.getListFromServer<{ merchants: IMerchant[] }>()
      : this.getLocalList<{ merchants: IMerchant[] }>();
    return method.pipe(
      tap(() => this.syncing$.set(false)),
      map((res) => {
        const { data } = res;
        const list = data?.merchants ?? [];
        this.list$.set(list);
        return list;
      })
    );
  }

  getLocalList<T>(): Observable<IHttpResponse<T>> {
    return this.getListFromServer<T>().pipe(shareReplay());
  }

  getListFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `admin/merchants`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  private getMchId(): Observable<string | null> {
    return this.storageService.get(selectedMerchantNamespace);
  }

  private setMchId(id: string): Observable<boolean> {
    return this.storageService.set(selectedMerchantNamespace, id);
  }
}
