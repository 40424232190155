import { Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SupportedLocaleCode, SupportedLocaleCodeType } from "./interfaces";
import { lastValueFrom } from "rxjs";

import { APP_LOCALE } from "../storage/constants";
import { take } from "rxjs/operators";
import { StorageHelperService } from "../storage";

@Injectable({
  providedIn: "root",
})
export class TranslateHelperService {
  currentLocale$ = signal<SupportedLocaleCodeType>("fr");

  constructor(
    private translate: TranslateService,
    private storage: StorageHelperService // private i18n: NzI18nService
  ) {}

  async init() {
    const operation = this.storage.get(APP_LOCALE);
    const locale =
      ((await lastValueFrom(operation)) as
        | SupportedLocaleCodeType
        | undefined) ?? SupportedLocaleCode.fr;

    this.setLang(locale);
    return locale;
  }

  setLang(langId: string): void {
    let locale: SupportedLocaleCodeType;

    switch (langId) {
      case SupportedLocaleCode.zh:
        // this.i18n.setLocale(zh_CN);
        this.translate.use(SupportedLocaleCode.zh);
        locale = SupportedLocaleCode.zh;
        break;
      case SupportedLocaleCode.en:
        // this.i18n.setLocale(en_US);
        this.translate.use(SupportedLocaleCode.en);
        locale = SupportedLocaleCode.en;
        break;
      case SupportedLocaleCode.fr:
        // this.i18n.setLocale(fr_BE);
        this.translate.use(SupportedLocaleCode.fr);
        locale = SupportedLocaleCode.fr;
        break;
      case SupportedLocaleCode.nl:
        // this.i18n.setLocale(nl_BE);
        this.translate.use(SupportedLocaleCode.nl);
        locale = SupportedLocaleCode.nl;
        break;

      default:
        // this.i18n.setLocale(fr_BE);
        this.translate.use(SupportedLocaleCode.fr);
        locale = SupportedLocaleCode.fr;
        break;
    }

    this.currentLocale$.set(locale);
    this.storage.set(APP_LOCALE, locale).pipe(take(1)).subscribe();
  }
}
