import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MenuCategoryApiService } from "@api/menu";
import { MerchantApiService } from "@api/merchant";
import { IMerchant } from "@api/merchant/interfaces";
import { ShopApiService } from "@api/shop";
import { trackByIndexFn } from "@helpers/common/functions";

@Component({
  selector: "app-merchant-selector",
  templateUrl: "./merchant-selector.component.html",
  styleUrls: ["./merchant-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MerchantSelectorComponent implements OnInit, OnDestroy {
  @Input() autoSelect = false;
  isLoading = false;
  list: IMerchant[] = [];
  mch$ = this.mchService.mch$;
  shops$ = this.shopService.list$;
  list$ = this.mchService.list$;
  syncing$ = this.mchService.syncing$;
  trackByIndex = trackByIndexFn;
  private destroy$ = new Subject<void>();
  constructor(
    private modalCtrl: ModalController,
    public mchService: MerchantApiService,
    private shopService: ShopApiService,
    private categoryService: MenuCategoryApiService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }
  ngOnInit() {
    this.getMchList();
  }

  ionViewDidEnter() {}

  async close(state: boolean) {
    await this.modalCtrl.dismiss(state);
  }

  async select(mch: IMerchant) {
    this.isLoading = true;
    this.mchService
      .selectMch(mch.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async () => {
          this.isLoading = false;
          this.shops$.set([]);
          this.categoryService.initAll();
          await this.close(true);
        },
      });
  }

  private getMchList(): void {
    this.mchService.getMchList().pipe(takeUntil(this.destroy$)).subscribe();
  }
}
