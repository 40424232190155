import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { environment } from "src/environments/environment";
import { ShopApiService } from "../shop";
import { IMenu } from "./interfaces";
import { MenuCategoryApiService } from "./menu-category.service";
import { MenuProductApiService } from "./menu-product.service";
import { MenuTaxApiService } from "./menu-tax.service";

@Injectable({
  providedIn: "root",
})
export class MenuApiService {
  coreEndPoint = environment.coreEndPoint;
  #list$ = new BehaviorSubject<{ data: IMenu[]; synced: boolean }>({
    data: [],
    synced: false,
  });
  list$ = this.#list$.asObservable();
  #listLoading$ = new BehaviorSubject<boolean>(false);
  listLoading$ = this.#listLoading$.asObservable();

  #menu$ = new BehaviorSubject<IMenu | null>(null);
  menu$ = this.#menu$.asObservable();
  #menuLoading$ = new BehaviorSubject<boolean>(false);
  menuLoading$ = this.#menuLoading$.asObservable();
  private shop$ = this.shopService.shop$;
  constructor(
    private http: HttpHelperService,
    private shopService: ShopApiService,
    private productService: MenuProductApiService,
    private categoryService: MenuCategoryApiService,
    private taxService: MenuTaxApiService
  ) {}

  set list(value: { data: IMenu[]; synced: boolean }) {
    this.#list$.next(value);
  }

  set menu(value: IMenu | null) {
    this.#menu$.next(value);
  }

  private set listLoading(value: boolean) {
    this.#listLoading$.next(value);
  }

  private set menuLoading(value: boolean) {
    this.#menuLoading$.next(value);
  }

  initALL(): void {
    this.list = { data: [], synced: false };
    this.menu = null;
    this.productService.initAll();
    this.categoryService.initAll();
  }

  getCarouselFromServerByTag<T>(tag: string): Observable<IHttpResponse<T>> {
    const path = `api/shop/${this.shop$()?.id}/menu/carousels/${tag}`;
    return this.http.get(path).pipe();
  }

  getMenuList(): Observable<IMenu[]> {
    return this.list$.pipe(
      switchMap((list) => {
        if (list.synced) {
          return of(list.data);
        }

        return this.getMenuListFromServer<IMenu[]>().pipe(
          map((res) => res.data)
        );
      })
    );
  }

  private getMenuListFromServer<T>(): Observable<IHttpResponse<T>> {
    this.listLoading = true;
    const path = `admin/menu`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => (this.listLoading = false)),
      tap((res) => {
        const { data } = res;
        if (data) {
          this.list = { data, synced: true };
        }
      })
    );
  }
}
