import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Output,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IonicModule } from "@ionic/angular";
import { MenuCategoryApiService } from "@api/menu";
import { MerchantApiService } from "@api/merchant";
import { IMerchant } from "@api/merchant/interfaces";
import { ShopApiService } from "@api/shop";
import { trackByIndexFn } from "@helpers/common/functions";

@Component({
  selector: "sidebar-merchant-selector",
  standalone: true,
  imports: [CommonModule, IonicModule],
  template: ` <ion-item-group>
    <ion-item
      button="true"
      lines="none"
      [detail]="false"
      (click)="select(mch)"
      [disabled]="updating$()"
      *ngFor="let mch of list$(); trackBy: trackByIndex"
    >
      <ion-label>
        <ion-text class="text-sm font-semibold block">
          {{ mch.name }}
        </ion-text>
        <ion-text color="medium" class="text-xs">
          {{ mch.id }}
        </ion-text>
      </ion-label>
      <ng-container *ngIf="mch.id === mch$().id">
        <ion-icon slot="end" color="primary" name="checkmark-circle"></ion-icon>
      </ng-container>
    </ion-item>
    <ion-item lines="full" [button]="true" [detail]="false" [disabled]="true">
      <ion-label>Create new merchant</ion-label>
      <ion-icon slot="start" name="add-circle-outline"></ion-icon>
    </ion-item>
  </ion-item-group>`,
  styleUrls: ["./sidebar-merchant-selector.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMerchantSelectorComponent {
  trackByIndex = trackByIndexFn;
  mchService = inject(MerchantApiService);
  mch$ = this.mchService.mch$;
  list$ = this.mchService.list$;
  updating$ = this.mchService.updating$;
  private destroyRef = inject(DestroyRef);
  private shopService = inject(ShopApiService);
  private categoryService = inject(MenuCategoryApiService);

  @Output() onMerchantChanged = new EventEmitter<IMerchant>();

  async select(mch: IMerchant) {
    if (mch.id === this.mch$()?.id) {
      return false;
    }

    this.mchService
      .selectMch(mch.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: async () => {
          this.shopService.list$.set([]);
          this.categoryService.initAll();
          this.onMerchantChanged.emit(mch);
        },
      });
  }
}
