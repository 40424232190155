/* eslint-disable @typescript-eslint/naming-convention */

export const printerEvents = {
    PRINTER_LIST_INITIALIZE_FAILED: {
        name: 'Printer list initialize failed', code: 'PRINTER_LIST_INITIALIZE_FAILED'
    },
    PRINTER_LIST_INITIALIZED: {
        name: 'Printer list initialized', code: 'PRINTER_LIST_INITIALIZED'
    },
    UNKNOWN_EVENT: {
        name: 'unknown event', code: 'UNKNOWN_EVENT'
    },
    PORT_SEARCH_START: {
        name: 'Port searching...', code: 'PORT_SEARCH_START'
    },
    PORT_SEARCH_STOP: {
        name: 'Port search stopped', code: 'PORT_SEARCH_STOP'
    },
    VERSION_SEARCH_START: {
        name: 'Version searching', code: 'VERSION_SEARCH_START'
    },
    VERSION_SEARCH_STOP: {
        name: 'Version search stopped', code: 'VERSION_SEARCH_STOP'
    },
    SERIAL_NUMBER_SEARCH_START: {
        name: 'Serial number searching', code: 'SERIAL_NUMBER_SEARCH_START'
    },
    SERIAL_NUMBER_SEARCH_STOP: {
        name: 'Serial number search stopped', code: 'SERIAL_NUMBER_SEARCH_STOP'
    },
    STATUS_LISTENER_START: {
        name: 'Listening status started', code: 'STATUS_LISTENER_START'
    },
    STATUS_LISTENER_STOP: {
        name: 'Listening status ended', code: 'STATUS_LISTENER_STOP'
    },
    SELF_CHECKING_START: {
        name: 'Self checking', code: 'SELF_CHECKING_START'
    },
    SELF_CHECKING_STOP: {
        name: 'Self check stopped', code: 'SELF_CHECKING_STOP'
    },
    PRINT_JOB_QUEUED: {
        name: 'Print job queued', code: 'PRINT_JOB_QUEUED'
    },
    PRINT_JOB_SENT: {
        name: 'Print job sent', code: 'PRINT_JOB_SENT'
    },
    PRINT_JOB_DONE: {
        name: 'Print job done', code: 'PRINT_JOB_DONE'
    },
    PRINT_JOB_FAILED: {
        name: 'Print job failed', code: 'PRINT_JOB_FAILED'
    },
    PRINTER_SAVED: {
        name: 'Printer saved', code: 'PRINTER_SAVED'
    },
    PRINTER_SAVE_FAILED: {
        name: 'Printer save failed', code: 'PRINTER_SAVE_FAILED'
    },
    PRINTER_UPDATED: {
        name: 'Printer updated', code: 'PRINTER_UPDATED'
    },
    PRINTER_UPDATE_FAILED: {
        name: 'Printer update failed', code: 'PRINTER_UPDATE_FAILED'
    },
    PRINTER_DELETED: {
        name: 'printer deleted', code: 'PRINTER_DELETED'
    },
    PRINTER_DELETED_FAILED: {
        name: 'printer delete failed', code: 'PRINTER_DELETED_FAILED'
    },
    PRINTER_NOT_FOUND: {
        name: 'Printer not found', code: 'PRINTER_NOT_FOUND'
    },
    PRINTER_CONNECTED: {
        name: 'Printer connected', code: 'PRINTER_CONNECTED'
    },
    PRINTER_CONNECT_FAILED: {
        name: 'Printer connect failed', code: 'PRINTER_CONNECT_FAILED'
    },
    PRINTER_DISCONNECTED: {
        name: 'Printer disconnected', code: 'PRINTER_DISCONNECTED'
    },
    PRINTER_DISCONNECT_FAILED: {
        name: 'Printer disconnect failed', code: 'PRINTER_DISCONNECT_FAILED'
    },
    PRINTER_OFFLINE: {
        name: 'Printer is offline', code: 'PRINTER_OFFLINE'
    },
    PRINTER_STATUS_CHECK_FAILED: {
        name: 'Printer status check failed', code: 'PRINTER_STATUS_CHECK_FAILED'
    },
    PRINTER_STATUS_CHECKING: {
        name: 'Printer status checking', code: 'PRINTER_STATUS_CHECKING'
    }

} as const;


