import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { StorageHelperService } from '../storage';
import { LIVEMODE } from '../storage/constants';


@Injectable({
  providedIn: 'root'
})
export class StateHelperService {
  livemode$: Observable<boolean>;
  #livemode$ = new BehaviorSubject<boolean>(true);
  private exemptPaths: string[] = ['settings'];
  constructor(
    private router: Router,
    private location: Location,
    private storage: StorageHelperService,
  ) {
    this.livemode$ = this.#livemode$.asObservable();
    this.init();

  }

  set livemode(state: boolean) {
    this.#livemode$.next(state);
    this.save(state ? 1 : 0);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.livemode$.pipe(
      take(1)
    );
  }


  routeParse(): Observable<boolean> {
    if (this.router.url.startsWith('/test')) {
      return of(false);
    }

    if (!this.router.url.startsWith('/test')) {
      return of(true);
    }

    return of(true);

  }



  private save(livemode: number): void {
    this.storage.set(LIVEMODE, livemode).pipe(
      take(1)
    ).subscribe();
  }

  private routeChange(): void {
    this.router.events.pipe(
      tap((event) => {
        // console.log(event);

      }),
      filter((event) => event instanceof NavigationEnd),
      switchMap(() => this.livemode$),
      tap((livemode) => {
        const path = this.location.path();

        if (livemode && path.startsWith('/test')) {
          const url = path.replace('/test', '');

          this.router.navigateByUrl(url, { replaceUrl: true });
        }

        if (!livemode && !path.startsWith('/test')) {
          //  check exemption paths
          const intersections = this.exemptPaths.filter(item => path.startsWith('/' + item));
          if (intersections.length === 0) {
            const url = path === '/' ? '/test' : `/test${path}`;
            this.router.navigateByUrl(url, { replaceUrl: true });
          }
        }
      })).subscribe();
  }



  private livemodeChange(): void {
    this.livemode$.pipe(
      tap((livemode) => {
        const path = this.location.path();
        if (livemode && path.startsWith('/test')) {
          const url = path.replace('/test', '');
          this.router.navigateByUrl(url, { replaceUrl: true });
        }

        if (!livemode && !path.startsWith('/test')) {
          //  check exemption paths
          const intersections = this.exemptPaths.filter(item => path.startsWith('/' + item));
          if (intersections.length === 0) {
            const url = path === '/' ? '/test' : `/test${path}`;
            this.router.navigateByUrl(url, { replaceUrl: true });
          }
        }
      })
    ).subscribe();
  }


  private init(): void {
    this.storage.get(LIVEMODE).pipe(
      take(1),
      switchMap((data: number) => {
        if (typeof data === 'number') {
          const livemode = data ? true : false;
          this.livemode = livemode;
          return of(livemode);
        } else {
          this.livemode = true;
          return of(true);
        }
      }),
      tap(() => {
        this.routeChange();
        this.livemodeChange();
      })
    ).subscribe();
  }

}
