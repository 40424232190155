import { AllPrinterPort } from "../interfaces";

export const printerPorts = {
  all: {
    name: "All",
    code: "ALL",
  },
  bluetooth: {
    name: "Bluetooth",
    code: "BT",
  },
  usb: {
    name: "Usb",
    code: "USB",
  },
  lan: {
    name: "Lan",
    code: "TCP",
  },
} as const;

export const PrinterPortList: AllPrinterPort[] = ["BT", "USB", "TCP"];
