<ion-header translucent="true">
  <ion-toolbar>
    <ion-title color="warning"> Please select a business</ion-title>
    <ng-container *ngIf="syncing$() || isLoading">
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title color="warning"> Please select a business</ion-title>
    </ion-toolbar>
  </ion-header>

  <ng-container *ngFor="let mch of list$(); trackBy: trackByIndex">
    <div class="group hover:cursor-pointer">
      <ion-card
        class="group hover:scale-95 duration-300"
        color="tertiary"
        (click)="select(mch)"
      >
        <ion-card-header>
          <ion-card-subtitle>{{ mch.id }}</ion-card-subtitle>
          <ion-card-subtitle>
            {{ mch.name }}
            <mch-livemode [livemode]="mch.livemode"></mch-livemode>
          </ion-card-subtitle>
          <ion-card-subtitle>{{ mch.url }}</ion-card-subtitle>
          <ion-content>
            {{ mch.description }}
          </ion-content>
        </ion-card-header>
      </ion-card>
    </div>
  </ng-container>
</ion-content>
