import { Injectable, computed, signal } from '@angular/core';
import { StorageHelperService } from '../storage';
import { APP_THEME } from '../storage/constants';
import { map, take, tap } from 'rxjs';

type TTheme = 'light' | 'dark'

@Injectable({
  providedIn: 'root'
})
export class ThemeHelperService {
  theme$ = signal<TTheme>('light');
  isDark$ = computed(() => this.theme$() === 'dark')
  constructor(
    private storage: StorageHelperService
  ) { }

  init() {
    return this.storage.get(APP_THEME).pipe(
      tap((res) => {
        const theme =
          (res as TTheme) ?? this.theme$();
        const isDark = theme === 'dark';
        this.setTheme(isDark);
      }),
      map(() => true)
    );
  }

  setTheme(isDark: boolean): void {
    document.body.classList.toggle('dark', isDark);
    this.theme$.set(isDark ? 'dark' : 'light');
    this.storage.set(APP_THEME, isDark ? 'dark' : 'light').pipe(take(1)).subscribe();
  }

}
