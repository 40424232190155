export const environment = {
  title: "WaiMai - Merchant Dashboard",
  businessName: "WaiMai - BetaResto",
  appVersion: require("../../package.json").version,
  coreEndPoint: "https://api.waimai.io/api",
  authEndPoint: "https://api.waimai.io/api",
  altCoreEndPoint: "http://135.125.233.139:3900/api",
  altAuthEndPoint: "http://135.125.233.139:3900/api",
  // coreEndPoint: "http://192.168.0.173:3900/api",
  // authEndPoint: "http://192.168.0.173:3900/api",
  apkUrl:
    "https://github.com/Drunkenpilot/waimai-release/raw/main/apk/app-debug.apk",

  native: true,
  production: true,
  withCredentials: true,
  mqtt: {
    hostname: "if60ec61.ala.us-east-1.emqxsl.com",
    port: 8084,
    path: "/mqtt",
    username: "local",
    password: "password",
    protocol: "wss",
    connectOnCreate: false,
  },
};
