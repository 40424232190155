import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { get } from "lodash";
import { Observable, zip } from "rxjs";
import { environment } from "src/environments/environment";
import { IHttpResponseError } from "../interfaces";
import { AuthCoreService } from "@api/auth";
import { StateHelperService } from "../../state";
import { MerchantApiService } from "@api/merchant";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.addAuthHeader(req).pipe(
      switchMap((tokenize) =>
        next
          .handle(tokenize)
          .pipe(catchError((error) => this.handleResponseError(error)))
      )
    );
  }

  addAuthHeader(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    const authService = this.injector.get(AuthCoreService);
    const stateService = this.injector.get(StateHelperService);
    const merchantApiService = this.injector.get(MerchantApiService);
    const mch$ = merchantApiService.mch$;
    // const cartService = this.injector.get(CartCoreService);
    // const merchantId = environment.merchantId;
    // if (req.url.startsWith('https://google.com')) {
    //   return of(req);
    // }

    return zip(stateService.livemode$).pipe(
      take(1),
      map((res) => {
        const [livemode] = res;

        let headers: HttpHeaders = new HttpHeaders();
        if (mch$()) {
          headers = headers.set("mch", `${mch$().id}`);
        }

        if (authService.accessToken$()) {
          headers = headers.set(
            "Authorization",
            `Bearer ${authService.accessToken$()}`
          );
        }

        // if (cartToken) {
        //   headers = headers.set('cart', `${cartToken}`);
        // }

        headers = headers.set("livemode", livemode ? "true" : "false");

        const tokenized = req.clone({
          withCredentials: environment.withCredentials,
          headers,
        });
        return tokenized;
      })
    );
  }

  handleResponseError(err: HttpErrorResponse): Observable<any> {
    let message;
    let problems;
    let error: IHttpResponseError;
    console.log(err);

    // const msgService = this.injector.get(NzMessageService);
    const { status, error: response } = err;
    if (typeof response !== "string" && "error" in response) {
      message = get(response, ["error", "message"], "Unknown error");
      problems = get(response, ["error", "problems"], []);
      error = response.error;
    } else {
      message = get(response, ["message"], "Unknown error");
      problems = get(response, ["problems"], []);
      error = response;
    }

    if (status === 0) {
      // msgService.error(`Service unavailable, please try again later`);
    }

    if (status === 400) {
      const title = `Http Request Error`;
      // msgService.error(`${message}`);
    }

    if (status === 401) {
      const auth = this.injector.get(AuthCoreService);
      // const msg = msgService.loading(`跳转中....`);

      auth
        .destroy()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            // msgService.remove(msg.messageId);
            this.router.navigate(["/session/login"]);
          }
        });
    }

    if (status === 403) {
      // msgService.warning(message);
      // this.toLoginPage();
    }

    if (status === 404) {
      // msgService.error(`404: Api Path not found`);
    }

    if (status === 500) {
      // msgService.error(`${message}`);
    }

    if (status === 503) {
      // msgService.warning(`${message}`);
    }

    if (status > 500) {
      // msgService.error(`Server Error, ${message}`);
    }

    if (problems.length > 0) {
      // msgService.error(`${problems}`);
    }

    return throwError(() => error);
  }
}
