import { Injectable, Injector } from "@angular/core";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DeviceHelperService } from "@helpers/device";
import { TranslateHelperService } from "@helpers/translate-helper";

import { AuthCoreService } from "../auth";

import { ApiStatusService } from "../status";
import { NetworkHelperService } from "@helpers/network";

@Injectable({
  providedIn: "root",
})
export class InitializerService {
  constructor(private injector: Injector) {}

  async load() {
    await this.injector.get(DeviceHelperService).init();
    await this.injector.get(NetworkHelperService).init;
    await this.injector.get(AuthCoreService).accessTokenInit();
    await this.injector.get(TranslateHelperService).init();

    const apiStatusService = this.injector.get(ApiStatusService);

    return new Promise((resolve, reject) => {
      apiStatusService
        .coreApiStatusGet()
        .pipe(
          switchMap((res) => {
            if (res?.code && ["maintenance", "outage"].includes(res.code)) {
              return of(false);
            } else {
              return of(true);
            }
          })
        )
        .subscribe({
          next: (res) => {
            resolve(true);
          },
        });
    });
  }
}

export const initProviderFactory = (provider: InitializerService) => () =>
  provider.load();
