import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { navData } from "./nav.data";
import { RouterModule } from "@angular/router";
import { SidebarNavItemComponent } from "@components/layout/sidebar/sidebar-nav-item";
import { TNavItem } from "./nav.type";

@Component({
  selector: "sidebar-nav",
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, SidebarNavItemComponent],
  template: `
    @for (item of pages; track $index) {
    <sidebar-nav-item [item]="item" [level]="level" />
    }
  `,
  styleUrls: ["./sidebar-nav.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavComponent {
  @Input() pages: TNavItem[] = navData;
  @Input() level = 0;
}
