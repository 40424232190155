import { Injectable, Signal, effect, signal } from "@angular/core";
import { IPrinter, Printer } from "./interfaces/printer";
import { PrintJob, PrintJobQueued } from "./interfaces/print";
import { generateId } from "@helpers/common/functions";

@Injectable({
  providedIn: "root",
})
export class PrinterManagementService {
  printers$ = signal<
    {
      id: string;
      device: IPrinter;
      jobs: {
        queued: PrintJobQueued[];
        failed: PrintJobQueued[];
        processing: PrintJobQueued[];
        completed: PrintJobQueued[];
      };
    }[]
  >([]);

  constructor() {
    this.watch();
  }

  addPrinter(device: IPrinter) {
    this.printers$.update((list) => {
      const prnt = list.find((item) => item.device.id === device.id);
      if (!prnt) {
        list.push({
          id: generateId(20),
          device,
          jobs: {
            queued: [],
            failed: [],
            processing: [],
            completed: [],
          },
        });
      }

      return list;
    });
  }

  removePrinter(printerId: string) {
    this.printers$.update((list) => {
      list = list.filter((item) => item.id !== printerId);
      return list;
    });
  }

  addJob(data: PrintJob, printers: string[]) {
    this.printers$.update((list) => {
      for (const id of printers) {
        const idx = list.findIndex((item) => item.id === id);
        if (idx >= 0) {
          data[id] = generateId(20);
          const job = data as PrintJobQueued;
          list[idx].jobs.queued.push(job);
        }
      }

      return list;
    });
  }

  removeJob(jobId: string, printerId: string) {
    this.printers$.update((list) => {
      const idx = list.findIndex((item) => item.id === printerId);
      if (idx >= 0) {
        list[idx].jobs.queued = list[idx].jobs.queued.filter(
          (job) => job.id !== jobId
        );
        list[idx].jobs.failed = list[idx].jobs.failed.filter(
          (job) => job.id !== jobId
        );
        list[idx].jobs.processing = list[idx].jobs.processing.filter(
          (job) => job.id !== jobId
        );
        list[idx].jobs.completed = list[idx].jobs.completed.filter(
          (job) => job.id !== jobId
        );
      }
      return list;
    });
  }

  printer(id: string): Signal<Printer> | null {
    const prnt = this.printers$().find((item) => item.device.id === id);
    // console.log(prnt);

    return prnt ? signal(prnt) : null;
  }

  private watch() {
    effect(() => {
      // console.dir(this.printers$());
    });
  }
}
