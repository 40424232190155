import { EventEmitter, Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Observable, Subscriber } from "rxjs";

import { v1 as uuidv1 } from "uuid";
import { printerEvents, printerVendors } from "../constants";
import { IPrinterEvent, IPrintJob, PrinterEvent } from "../interfaces";
import {
  IPrintBarCodeObj,
  IPrinterQRCode,
  IPrintImageObj,
  IPrintObj,
} from "../interfaces/print";

import { PrintEventService } from "../print-event.service";
declare let navigator: any;
@Injectable({
  providedIn: "root",
})
export class BrowserPrinterService {
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;
  constructor(
    private platform: Platform,
    private eventService: PrintEventService
  ) {
    this.printEvent$ = this.eventService.printEvent$;
  }

  search(mokeUp = false): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (mokeUp) {
          return observer.next({
            event: printerEvents.PORT_SEARCH_STOP,
            data: {
              printers: [
                {
                  vendor: printerVendors.browser,
                  portName: "1",
                  id: uuidv1(),
                },
              ],
            },
          });
        } else {
          return this.cordovaPlatformRequired(
            printerEvents.PORT_SEARCH_STOP,
            observer
          );
        }
      }

      return observer.next({ event: printerEvents.PORT_SEARCH_START });
    });
  }

  private cordovaPlatformRequired(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent>
  ) {
    observer.error({ name: eventType, message: "cordova_platform_required" });
    observer.complete();
    return observer;
  }
}
