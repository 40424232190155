import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnInit,
} from "@angular/core";
import { AlertController, IonicModule } from "@ionic/angular";
import { SidebarMerchantSelectorComponent } from "../sidebar-merchant-selector";
import { AuthCoreService } from "@api/auth";
import { take } from "rxjs";
import { NavHelperService } from "@helpers/nav";

@Component({
  selector: "sidebar-popover",
  standalone: true,
  imports: [CommonModule, IonicModule, SidebarMerchantSelectorComponent],
  template: `
    <ion-content>
      <ion-list>
        <ion-list-header>
          <ion-label class="text-lg">Merchants</ion-label>
        </ion-list-header>
        <sidebar-merchant-selector></sidebar-merchant-selector>
        <ion-list-header>
          <ion-label class="text-lg">Account</ion-label>
        </ion-list-header>
        <ion-item-group>
          <ion-item
            [href]="'/settings/account'"
            lines="none"
            [button]="true"
            [detail]="false"
          >
            <ion-label>Account Setting</ion-label>
            <ion-icon slot="start" name="person-circle"></ion-icon>
          </ion-item>
          <ion-item
            lines="none"
            [button]="true"
            [detail]="false"
            (click)="signOut()"
          >
            <ion-label>Sign out</ion-label>
            <ion-icon slot="start" name="log-out"></ion-icon>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </ion-content>
  `,
  styleUrls: ["./sidebar-popover.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarPopoverComponent implements OnInit {
  private authService = inject(AuthCoreService);
  private alertService = inject(AlertController);
  private nav = inject(NavHelperService);

  ngOnInit(): void {}

  async signOut() {
    const alert = await this.alertService.create({
      header: "Sign Out",
      message: "Do you want to sign out ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes",
          role: "confirm",
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === "confirm") {
      this.authService
        .signOut()
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            const { success } = res;

            if (success) {
              this.nav.to("/");
            }
          },
        });
    }
  }
}
