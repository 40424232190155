import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mch-livemode',
  templateUrl: './merchant-livemode.component.html',
  styleUrls: ['./merchant-livemode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MerchantLivemodeComponent implements OnInit {
  @Input() livemode: 0 | 1;

  constructor() { }

  ngOnInit() { }

}
