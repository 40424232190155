import { computed, Injectable, Signal, signal } from "@angular/core";
import { lastValueFrom, Observable, of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { StorageHelperService } from "@helpers/storage";
import { StateHelperService } from "@helpers/state";
import { HttpHelperService } from "@helpers/http";

import {
  USER_ACCOUNT_ACCESS_TOKEN,
  USER_AUTH_REFRESH_TOKEN,
} from "@helpers/storage/constants";
import { IHttpResponse } from "@helpers/http/interfaces";
import { Member } from "../member/models";

@Injectable({
  providedIn: "root",
})
export class AuthCoreService {
  accessToken$ = signal<string | null>(null);
  refreshToken$ = signal<string | null>(null);

  authUser$ = signal<Member>(new Member());
  email$: Signal<string | null> = computed(() => this.authUser$().email);
  isLoggedIn$ = computed(() => this.authUser$().isLoggedIn);
  #endPoint: string;

  constructor(
    private storageService: StorageHelperService,
    private stateService: StateHelperService,
    private http: HttpHelperService
  ) {
    this.#endPoint = environment.authEndPoint;
  }

  set accessToken(value: string | null) {
    this.accessToken$.set(value);
    this.accessTokenSave(value).pipe(take(1)).subscribe();
  }

  private set refreshToken(value: string | null) {
    this.refreshToken$.set(value);
    this.refreshTokenSave(value).pipe(take(1)).subscribe();
  }

  destroy(): Observable<boolean> {
    this.accessToken = null;
    this.refreshToken = null;
    this.stateService.livemode = true;
    this.authUser$.set(new Member());
    return of(true);
  }

  async accessTokenInit() {
    const operation = this.storageService.get(USER_ACCOUNT_ACCESS_TOKEN);
    const token = await lastValueFrom(operation);
    this.accessToken$.set(token);
  }

  authUserGet(): Observable<Member> {
    const path = `member/profile`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(this.http.errorMsgHandler()),
      map((res: IHttpResponse<any>) => {
        const account = new Member(res?.data);
        this.authUser$.set(account);
        return account;
      })
    );
  }

  verifyEmailSend() {
    const path = `account/auth/verify/email/send`;
    return this.http.post(path, {}).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  referUrlGet(): Observable<IHttpResponse<any>> {
    const path = `account/refer/url/build`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  passwordTokenGet(): Observable<IHttpResponse<any>> {
    const path = "account/auth/password/token/retrieve";
    return this.http
      .post(path, {})
      .pipe(
        catchError(this.http.catch()),
        tap(this.http.errorMsgHandler()),
        tap(this.http.msgHandler())
      );
  }

  passwordReset(body: any): Observable<IHttpResponse<any>> {
    const path = `password/reset/token`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.errorMsgHandler()),
      tap(this.http.msgHandler()),
      switchMap((res) => {
        if (res?.data) {
          return this.signOut();
        } else {
          return of(res);
        }
      })
    );
  }

  passwordRecoverEmail(body: any) {
    const path = `password/forgot/token`;
    return this.http
      .post(path, body)
      .pipe(
        catchError(this.http.catch()),
        tap(this.http.errorMsgHandler()),
        tap(this.http.msgHandler())
      );
  }

  signUpEmail(body: any): Observable<IHttpResponse<any>> {
    const path = `sign-up/email`;
    return this.http
      .post(path, body)
      .pipe(
        take(1),
        catchError(this.http.catch()),
        tap(this.http.errorMsgHandler()),
        tap(this.http.msgHandler())
      );
  }

  signUpEmailCheck(body: { email: string }): Observable<IHttpResponse<any>> {
    const path = `email/check`;
    return this.http
      .post(path, body)
      .pipe(
        take(1),
        catchError(this.http.catch()),
        tap(this.http.errorMsgHandler()),
        tap(this.http.msgHandler())
      );
  }

  emailVerifyCode(body: {
    email: string;
    code: string;
  }): Observable<IHttpResponse<any>> {
    const path = `email/verify/code`;
    return this.http
      .post(path, body)
      .pipe(
        take(1),
        catchError(this.http.catch()),
        tap(this.http.msgHandler())
      );
  }

  emailVerifyCodeSend(): Observable<IHttpResponse<any>> {
    const path = `email/verify/code/send`;
    return this.http
      .post(path, {})
      .pipe(
        take(1),
        catchError(this.http.catch()),
        tap(this.http.msgHandler())
      );
  }

  signInEmail(body: any): Observable<IHttpResponse<any>> {
    const path = `session/admin/sign-in`;
    return this.http.post(path, body).pipe(
      catchError(this.http.catch()),
      tap(this.http.msgHandler()),
      map((res: IHttpResponse<any>) => {
        const { data } = res;
        if (data) {
          const { sessionToken, refreshToken, account } = data;
          this.accessToken = sessionToken;
          this.refreshToken = refreshToken;
          this.authUser$.set(new Member(account));
        }
        return res;
      })
    );
  }

  signOut(): Observable<IHttpResponse<any>> {
    const path = `session/admin/sign-out`;
    return this.http.post(path, {}).pipe(
      take(1),
      catchError(this.http.catch()),
      tap(this.http.errorMsgHandler()),
      tap(this.http.msgHandler()),
      tap((res: IHttpResponse<any>) => {
        if (res?.success) {
          this.destroy();
        }
      })
    );
  }

  sendInvitation(body: any): Observable<IHttpResponse<any>> {
    const path = `account/refer/url/send`;
    return this.http
      .post(path, body)
      .pipe(
        take(1),
        catchError(this.http.catch()),
        tap(this.http.errorMsgHandler())
      );
  }

  private accessTokenSave(token: string | null): Observable<boolean> {
    return this.storageService
      .set(USER_ACCOUNT_ACCESS_TOKEN, token)
      .pipe(take(1));
  }

  private refreshTokenSave(token: string | null): Observable<boolean> {
    return this.storageService
      .set(USER_AUTH_REFRESH_TOKEN, token)
      .pipe(take(1));
  }
}
