import { inject } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { MerchantApiService } from "@api/merchant";
import { DeviceHelperService } from "@helpers/device";
import { NetworkHelperService } from "@helpers/network";
import { ThemeHelperService } from "@helpers/theme";
import { TranslateHelperService } from "@helpers/translate-helper";
import { MenuController, ToastController } from "@ionic/angular";
import { forkJoin } from "rxjs";

export const appGuardFunction: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const menuCtrl = inject(MenuController);
  const toastCtrl = inject(ToastController);
  // your  logic goes here
  const deviceService = inject(DeviceHelperService);
  const networkService = inject(NetworkHelperService);
  const themeService = inject(ThemeHelperService);
  const translateService = inject(TranslateHelperService);

  return await new Promise(async (resolve) => {
    const toast = await toastCtrl.create({
      message: "Initializing Settings...",
      position: "top",
      color: "warning",
    });

    await toast.present();
    const observe = forkJoin({
      theme: themeService.init(),
      device: deviceService.init(),
      network: networkService.init(),
      translate: translateService.init(),
    }).subscribe({
      next: () => {
        observe.unsubscribe();
        toast.dismiss();
        menuCtrl.enable(true);
        resolve(true);
      },
    });
  });
};
