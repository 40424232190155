import { Injectable, computed, signal } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map, shareReplay, catchError, tap } from "rxjs/operators";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { StorageHelperService } from "@helpers/storage";
import { environment } from "src/environments/environment";

import { selectedShopNamespace } from "@helpers/storage/constants";
import { IShop } from "./interfaces";

@Injectable({
  providedIn: "root",
})
export class ShopApiService {
  coreEndPoint = environment.coreEndPoint;

  #statusLoading$ = new BehaviorSubject<boolean>(false);
  statusLoading$ = this.#statusLoading$.asObservable();
  list$ = signal<IShop[]>([]);
  shop$ = computed(() => {
    const shop = this.list$().find((shop) => shop.selected);
    return shop;
  });

  syncing$ = signal(false);

  constructor(
    private storageService: StorageHelperService,
    private http: HttpHelperService
  ) {}

  set statusLoading(state: boolean) {
    this.#statusLoading$.next(state);
  }

  getShopList(): Observable<IShop[]> {
    if (this.list$().length) {
      return of(this.list$());
    }

    return this.initList(true);
  }

  initList(fromServer = false): Observable<IShop[]> {
    this.syncing$.set(true);
    const method = fromServer
      ? this.getListFromServer<{ merchants: IShop[] }>()
      : this.getLocalList<{ merchants: IShop[] }>();
    return method.pipe(
      tap(() => this.syncing$.set(false)),
      map((res) => {
        const { data } = res;
        const list = data?.shops ?? [];
        return list;
      })
    );
  }

  init(): Observable<{ shop: IShop | null; list: IShop[] }> {
    return forkJoin({
      list: this.getShopList(),
      shopId: this.getShopId(),
    }).pipe(
      map((res) => {
        let shop: IShop | null = null;
        const { list, shopId } = res;

        if (!shopId) {
          return { shop, list };
        }

        this.list$.update((list) => {
          list.map(
            (shop) => (shop.selected = shop.id === shopId ? true : false)
          );
          return list;
        });
        shop = this.shop$();
        if (!shop) {
          this.setShopId(null);
        }
        return { shop, list };
      })
    );
  }

  selectShop(id: number | string | null): Observable<boolean> {
    return this.setShopId(id).pipe(
      tap((res) => {
        if (res) {
          this.list$.update((list) => {
            list.map((shop) => (shop.selected = shop.id === id ? true : false));
            return [...list];
          });
        }
      })
    );
  }

  getLocalList<T>(): Observable<IHttpResponse<T>> {
    return this.getListFromServer<T>().pipe(shareReplay());
  }

  getListFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `admin/shops`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),

      tap((res: IHttpResponse<T>) => {
        const { data } = res;
        const list = data?.shops ?? [];
        this.list$.set(list);
      })
    );
  }

  updateService<T>(id: number, body: any): Observable<IHttpResponse<T>> {
    this.statusLoading = true;
    const path = `admin/shop/${id}/service/status`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => (this.statusLoading = false))
    );
  }

  updateContact<T>(id: number, body: any): Observable<IHttpResponse<T>> {
    const path = `admin/shop/${id}/contact`;
    return this.http.put(path, body).pipe(catchError(this.http.catch()));
  }

  private getShopId(): Observable<string | number | null> {
    return this.storageService.get(selectedShopNamespace);
  }

  private setShopId(id: string | number): Observable<boolean> {
    return this.storageService.set(selectedShopNamespace, id);
  }
}
