import { Injectable, inject, signal } from "@angular/core";
import { HttpHelperService } from "@helpers/http";
import { AlertController, ToastController } from "@ionic/angular";
import {
  injectQuery,
  injectInfiniteQuery,
  injectMutation,
  injectQueryClient,
} from "@ngneat/query";
import { environment } from "src/environments/environment";
import { TAuthPasswordDTO } from "./interfaces";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthPasswordApiService {
  #mutation = injectMutation();
  #http = inject(HttpHelperService);
  #coreEndPoint = environment.coreEndPoint;

  private alertCtrl = inject(AlertController);
  private toastCtrl = inject(ToastController);

  counter = signal(0);
  private timer = signal(null);

  resetPassword<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.presentToast("Password reset succeed!");
      },
      retry: false,
      onError: this.errorHandler,
      mutationFn: ({ body }: { body: TAuthPasswordDTO }) => {
        const path = `session/admin/password/reset`;
        return this.#http.post(path, body).pipe(map((res) => res.data));
      },
    });
  }

  sendCode<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.presentToast("A verification code has been sent !");
        this.startCount();
      },
      onError: this.errorHandler,
      retry: false,
      mutationFn: ({ body }: { body: Pick<TAuthPasswordDTO, "email"> }) => {
        const path = `session/admin/password/forgot`;
        return this.#http.post(path, body).pipe(map((res) => res.data));
      },
    });
  }

  private startCount() {
    this.counter.set(60);
    const timer = setInterval(() => {
      if (this.counter() <= 0) {
        this.counter.set(0);
        this.stopCount();
      } else {
        this.counter.update((num) => num - 1);
      }
    }, 1000);

    this.timer.set(timer);
  }
  private stopCount() {
    clearInterval(this.timer());
  }

  errorHandler = async (error: any, variables, context) => {
    if (typeof error === "string") {
      const alert = await this.alertCtrl.create({
        header: `Error`,
        // subHeader: "Error",
        message: error,
        buttons: ["OK"],
      });

      alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        header: error.name,
        subHeader: error.message,
        message: error.problems ? error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }

    if (error.error instanceof ProgressEvent) {
      const alert = await this.alertCtrl.create({
        header: `ProgressEvent Error`,
        // subHeader: "Error",
        message: "Please try later",
        buttons: ["OK"],
      });

      alert.present();
    } else if (error.error) {
      const alert = await this.alertCtrl.create({
        header: error.error.name,
        subHeader: error.error.message,
        message: error.error.problems ? error.error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }
  };

  async presentToast(
    message: string,
    color: "danger" | "success" | "warning" = "success"
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
          handler: () => {
            console.log("Dismiss clicked");
          },
        },
      ],
      position: "top",
      positionAnchor: "header",
      color,
      swipeGesture: "vertical",
    });

    await toast.present();
  }
}
