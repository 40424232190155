import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { environment } from "src/environments/environment";

import { MerchantApiService } from "@api/merchant";
import { SidebarPopoverComponent } from "@layout/sidebar/sidebar-popover";
import { RouterModule } from "@angular/router";
import { SidebarNavComponent } from "@layout/sidebar/sidebar-nav";
import { DeviceHelperService } from "@helpers/device";

@Component({
  selector: "sidebar",
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,

    SidebarPopoverComponent,
    SidebarNavComponent,
  ],
  template: `
    <ion-list id="inbox-list">
      <ion-list-header>
        <i class="bi bi-egg-fried mr-2"></i> Wai Mai - Dashboard
      </ion-list-header>

      <ion-card
        button
        color="warning"
        id="mch-popover-button"
        class="hover:shadow-lg hover:scale-95 hover:duration-500 hover:transition-all"
      >
        <ion-card-header *ngIf="mch$()">
          <ion-card-subtitle class="text-xs font-normal">
            MID: {{ mch$().id }}
          </ion-card-subtitle>
          <ion-card-title class="text-lg items-center">
            {{ mch$().name }} <i class="bi bi-caret-down-fill"></i>
          </ion-card-title>
        </ion-card-header>

        <ion-popover
          trigger="mch-popover-button"
          [dismissOnSelect]="true"
          size="cover"
        >
          <ng-template>
            <sidebar-popover></sidebar-popover>
          </ng-template>
        </ion-popover>
      </ion-card>

      <sidebar-nav></sidebar-nav>
    </ion-list>

    <ion-list id="labels-list">
      <ion-item lines="none" class="text-center">
        <ion-label>Version: {{ device().appVersion }}</ion-label>
      </ion-item>
    </ion-list>
  `,
  styleUrls: ["./sidebar.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  appVersion = environment.appVersion;

  private mchService = inject(MerchantApiService);
  device = inject(DeviceHelperService).device$;
  mch$ = this.mchService.mch$;

  async ngOnInit() {}
}
