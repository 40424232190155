import { inject, Injectable } from "@angular/core";
import { map } from "rxjs";
import { HttpHelperService } from "@helpers/http";
import { environment } from "src/environments/environment";
import { AlertController, ToastController } from "@ionic/angular";
import {
  injectQuery,
  injectInfiniteQuery,
  injectMutation,
  injectQueryClient,
  toPromise,
} from "@ngneat/query";
import qs from "qs";

@Injectable({
  providedIn: "root",
})
export class MenuTaxApiService {
  #query = injectQuery();
  #queryInfinite = injectInfiniteQuery();

  #mutation = injectMutation();
  #client = injectQueryClient();
  #http = inject(HttpHelperService);
  #coreEndPoint = environment.coreEndPoint;

  private alertCtrl = inject(AlertController);
  private toastCtrl = inject(ToastController);

  getList(menuId: number, searchQuery = {}) {
    return this.#queryInfinite({
      queryKey: ["taxes"],

      queryFn: ({ pageParam, signal }) => {
        const queries = qs.stringify({
          ...searchQuery,
          ...{ skip: pageParam },
        });

        const path = `admin/menu/${menuId}/taxes?${queries}`;

        return toPromise({
          source: this.#http.get(path).pipe(map((res) => res.data)),
          signal,
        });
      },
      // staleTime: 5000,
      initialPageParam: 0,
      getPreviousPageParam: (firstPage) => null,
      getNextPageParam: (lastPage) => {
        if (!lastPage) return null;
        const { count, skip, limit } = lastPage;
        if (count <= skip + limit) return null;
        return lastPage.skip + lastPage.limit;
      },
    });
  }

  getAll(menuId: number) {
    return this.#query({
      queryKey: ["taxes"],
      queryFn: () => {
        const queries = qs.stringify({
          limit: 10000,
        });
        const path = `admin/menu/${menuId}/taxes?${queries}`;
        return this.#http
          .get(path)
          .pipe(map((res) => res.data))
          .pipe(map((res) => res.taxes));
      },
      staleTime: 5000,
    });
  }
}
