import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
  PrinterGuardFunction,
  apiGuardFunction,
  appGuardFunction,
} from "@api/initializer";

const routes: Routes = [
  {
    path: "",
    canActivate: [apiGuardFunction, appGuardFunction, PrinterGuardFunction],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
