import { PaperWidth } from "../interfaces/print";

export const paperWidths = {
  mm50: {
    name: "50mm",
    code: 385,
  },
  mm80: {
    name: "80mm",
    code: 576,
  },
} as const;

export const PaperWidthList: PaperWidth[] = [
  paperWidths.mm50,
  paperWidths.mm80,
];
