import { Observable } from "rxjs";
import {
  ThermalPrinterPlugin,
  Printer,
  ErrorResult,
} from "thermal-printer-cordova-plugin-large-image/src";
declare let ThermalPrinter: ThermalPrinterPlugin;

export const requestBTPermission = () => {
  ThermalPrinter.requestBTPermissions(
    { type: "bluetooth", id: "xxx" },
    function (result) {
      console.log(result);
    },
    function (error) {
      console.log(error);
    }
  );
};

export const checkUsbPermission = () => {
  return new Observable((o) => {
    ThermalPrinter.listPrinters(
      { type: "usb" },
      (list: Printer[]) => {
        const printer = list.find((p) => p.deviceId);
        if (printer) {
          requestUsbPermission(printer).then((res) => {
            if (res) {
              o.next(true);
            } else {
              o.next(false);
            }
          });
        } else {
          o.next(false);
        }
      },
      (err: ErrorResult) => {
        console.log(err);
        o.next(false);
      }
    );
  });
};

export const requestUsbPermission = (printer: Printer) => {
  return new Promise((resolve) => {
    ThermalPrinter.requestPermissions(
      { type: "usb", id: printer.deviceId },
      function (result) {
        console.log(result);
        if (result.granted) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      function (error) {
        console.log(error);
        resolve(false);
      }
    );
  });
};
