import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IHttpResponse } from "./interfaces";
import { environment } from "src/environments/environment";
import { get } from "lodash";

import { of } from "rxjs";
import { DeviceHelperService } from "@helpers/device";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class HttpHelperService {
  #coreEndPoint = environment.coreEndPoint;
  private device = inject(DeviceHelperService).device$;
  private isAndroid = inject(Platform).is("android");
  constructor(private http: HttpClient) {
    if (this.isAndroid && this.device()?.androidSDKVersion <= 23) {
      this.#coreEndPoint = environment.altCoreEndPoint;
    }
  }

  public get(path: string, options?: any): Observable<any> {
    const url = path.includes("http") ? path : `${this.#coreEndPoint}/${path}`;

    return this.http.get(url, options);
  }

  public post(path: string, body: any = {}, options?: any): Observable<any> {
    const url = path.includes("http") ? path : `${this.#coreEndPoint}/${path}`;
    return this.http.post(url, body, options);
  }

  public put(path: string, body: any = {}, options?: any): Observable<any> {
    const url = path.includes("http") ? path : `${this.#coreEndPoint}/${path}`;
    return this.http.put(url, body, options);
  }

  public delete(path: string, options?: any): Observable<any> {
    const url = path.includes("http") ? path : `${this.#coreEndPoint}/${path}`;
    return this.http.delete(url, options);
  }

  public errorHandler(): any {
    return (res: IHttpResponse<any>) => {
      if (res.error) {
        const message = get(res.error, ["message"], "Unknown error");
        // this.msgService.error(message);
        const problems = get(res.error, ["problems"], []);

        if (problems.length > 0) {
          for (const problem of problems) {
            // this.msgService.error(problem);
          }
        }

        return of(null);
      } else {
        if (res.data) {
          return of(res.data);
        } else {
          return of(true);
        }
      }
    };
  }

  catch(): (res: IHttpResponse<any>) => Observable<IHttpResponse<any>> {
    return (res: IHttpResponse<any>) => of(res);
  }

  errorMsgHandler(): (res: IHttpResponse<any>) => void {
    return (res: IHttpResponse<any> | HttpErrorResponse) => {
      if ("ok" in res) {
        if (!res.status) {
          if (navigator.onLine) {
            // this.msgService.create('error', `API地址: ${res.url} 不可用`);
          } else {
            // this.msgService.create('error', `网络已离线`);
          }
        }
      } else {
        const { error } = res;
        if (error && error.message) {
          // this.msgService.create('error', error.message);
        }
        if (error && error.problems) {
          for (const problem of error.problems) {
            // this.msgService.create('error', JSON.stringify(problem));
          }
        }
      }
    };
  }

  msgHandler(
    type: "success" | "info" | "warning" | "error" | "loading" | string = "info"
  ): (res: IHttpResponse<any>) => void {
    return (res: IHttpResponse<any> | HttpErrorResponse) => {
      if (!("ok" in res)) {
        if (res && res.message) {
          // this.msgService.create(type, res.message);
        }
      }
    };
  }
}
