import { inject } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { ApiStatusService } from "@api/status";
import { MenuController } from "@ionic/angular";

export const apiGuardFunction: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const menuCtrl = inject(MenuController);

  const api = inject(ApiStatusService);
  const router = inject(Router);
  const isMaintenancePage =
    state.url === "/redirect/maintenance" ? true : false;

  return await new Promise((resolve) => {
    if (!["normal"].includes(api.status().code) && !isMaintenancePage) {
      router.navigate(["/redirect/maintenance"]);
    }

    if (["normal"].includes(api.status().code) && isMaintenancePage) {
      router.navigate(["/"]);
    }
    menuCtrl.enable(false);
    resolve(true);
  });
};
