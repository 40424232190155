import { ITimeDuration } from "../interfaces";

export const timeDurationFn = (minutes: number): ITimeDuration => {
    const obj: ITimeDuration = {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
    };

    if (minutes < 60) {
        obj.minutes = minutes > 0 ? minutes : 0;
        return obj;
    }

    let hours = minutes / 60;

    if (hours < 24) {
        obj.hours = Math.floor(hours);
        obj.minutes = Number(((hours - Math.floor(hours)) * 60).toFixed(0));
        return obj;
    }

    const days = minutes / 60 / 24;


    obj.days = Math.floor(days);
    hours = Number(((days - Math.floor(days)) * 24));
    obj.hours = Math.floor(hours);
    obj.minutes = Number(((hours - Math.floor(hours)) * 60).toFixed(0));

    return obj;

};
