import { EventEmitter, Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Observable, Subscriber } from "rxjs";

import { v1 as uuidv1 } from "uuid";
import { printerEvents, printerVendors } from "../constants";
import { IPrinterEvent, IPrintJob, PrinterEvent } from "../interfaces";
import { IPrintImageObj, IPrintObj } from "../interfaces/print";

import { PrintEventService } from "../print-event.service";
import {
  ThermalPrinterPlugin,
  Printer,
  ErrorResult,
  PrinterToUse,
} from "thermal-printer-cordova-plugin-large-image/src";
import { IPrinter } from "../interfaces/printer";

declare let navigator: any;

declare let ThermalPrinter: ThermalPrinterPlugin;

const printerType = (printer: IPrinter) => {
  let type: "bluetooth" | "usb" = "bluetooth";
  switch (printer.port) {
    case "BT":
      type = "bluetooth";
      break;

    case "USB":
      type = "usb";
      break;

    default:
      break;
  }
  return type;
};

const paperWidthMM = (px: number) => {
  let width = 70;
  switch (px) {
    case 385:
      width = 50;
      break;

    default:
      break;
  }
  return width;
};

const convertToPrinter = (item: Printer) => {
  const printer: IPrinter = {};
  printer.modelName = item.productName ? item.productName : item.name;
  printer.portName = item.address ? item.address : item.deviceId;
  printer.usbSerialNumber = item.serialNumber;
  if (item.productName) {
    printer.name = item.productName;
  }
  return printer;
};
@Injectable({
  providedIn: "root",
})
export class EscPosPrinterService {
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;

  constructor(
    private platform: Platform,
    private eventService: PrintEventService
  ) {
    this.printEvent$ = this.eventService.printEvent$;
  }

  search(printer: IPrinter, mokeUp = false): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (mokeUp) {
          return observer.next({
            event: printerEvents.PORT_SEARCH_STOP,
            data: {
              printers: [
                {
                  vendor: printerVendors.escpos,
                  portName: "1",
                  id: uuidv1(),
                },
              ],
            },
          });
        } else {
          return this.cordovaPlatformRequired(
            printerEvents.PORT_SEARCH_STOP,
            observer
          );
        }
      }

      const type = printerType(printer);

      ThermalPrinter.listPrinters(
        { type },
        (res: Printer[]) =>
          this.onFnSuccess(printerEvents.PORT_SEARCH_STOP, observer, res),
        (res: ErrorResult) =>
          this.onFnFailed(printerEvents.PORT_SEARCH_STOP, observer, res)
      );

      return observer.next({ event: printerEvents.PORT_SEARCH_START });
    });
  }

  private disconnected({ type, id }: PrinterToUse) {
    ThermalPrinter.disconnectPrinter(
      { type, id },
      () => {
        console.log("Successfully disconnected!");
      },
      (error) => {
        console.error("Printing error", error);
      }
    );
  }

  printString(
    copy = 1,
    printer: IPrinter,
    data: IPrintObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event = {
          name: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }

      const type = printerType(printer);
      const id = printer.portName;

      for (let i = 0; i < copy; i++) {
        const counter = i * 1000;
        setTimeout(() => {
          console.log("native called", i, counter);
          ThermalPrinter.printFormattedText(
            {
              type,
              id, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
              text: data.text,
              address: printer.tcpAddress,
              port: printer.tcpPort,
              printerDpi: printer.dpi,
              printerWidthMM: data.paperWidth,
              mmFeedPaper: printer.feederWidth,
            },
            () =>
              this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, {
                job,
                index: i,
                type,
                id,
              }),
            () =>
              this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, {
                job,
                index: i,
                type,
                id,
              })
          );
        }, counter);
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printImage(
    printer: IPrinter,
    data: IPrintImageObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }
      console.log("ESC POS print called", data);

      const type = printerType(printer);
      const id = printer.portName;

      ThermalPrinter.bitmapToHexadecimalStringLarge(
        {
          type,
          id,
          base64: data.base64Image,
          printerDpi: printer.dpi,
          printerWidthMM: paperWidthMM(data.paperWidth),
          mmFeedPaper: printer.feederWidth,
        },
        async (arr: string[]) => {
          // console.log(arr);
          let textToPrint = "";
          for (const str of arr) {
            textToPrint += `[C]<img>${str}</img>\n`;
          }

          // console.log(textToPrint);

          ThermalPrinter.printFormattedTextAndCut(
            {
              type,
              id, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
              text: textToPrint,
              address: printer.tcpAddress,
              port: printer.tcpPort,
              printerDpi: printer.dpi,
              printerWidthMM: paperWidthMM(data.paperWidth),
              mmFeedPaper: printer.feederWidth,
            },
            () =>
              this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, {
                job,
                type,
                id,
              }),
            () =>
              this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, {
                job,

                type,
                id,
              })
          );
        },
        () =>
          this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, {
            job,
            type,
            id,
          })
      );

      observer?.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  private cleanCache(): void {}

  private onPrintFinished(printResult: any, params: any): IPrinterEvent {
    console.log("print result", printResult);
    this.cleanCache();
    if (params && params.file) {
      params.file.remove(() => {}, this.onFnFailed());
    }
    const event: IPrinterEvent = {
      event: printerEvents.PRINT_JOB_DONE,
      message: "print job done",
      data: { ...printResult, ...params },
    };
    return event;
  }

  private onPortSearchFinished(searchResult: Array<Printer>): IPrinterEvent {
    console.log("searchResult", searchResult);
    let event: IPrinterEvent = {};
    if (searchResult.length > 0) {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_found",
        data: {
          printers: searchResult
            .filter(
              (item) =>
                item.productName !== "AX88772B" && item.name !== "AX88772B"
            )
            .map((item) => {
              return {
                id: uuidv1(),
                vendor: printerVendors.escpos,
                ...convertToPrinter(item),
                selfChecking: false,
              };
            }),
        },
      };
    } else {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_not_found",
        data: {
          printers: [],
        },
      };
    }

    return event;
  }

  private onFnSuccess(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    data?: any,
    params?: any
  ) {
    let event: IPrinterEvent = {};
    switch (eventType) {
      case printerEvents.PORT_SEARCH_STOP:
        event = this.onPortSearchFinished(data);
        break;
      case printerEvents.SERIAL_NUMBER_SEARCH_STOP:
        event = {
          data: {
            printer: {
              serialNumber: data,
            },
          },
        };
        break;

      case printerEvents.VERSION_SEARCH_STOP:
        event = {
          data: {
            printer: {
              version: data,
            },
          },
        };
        break;

      case printerEvents.PRINT_JOB_DONE:
        event = this.onPrintFinished(data, params);
        break;

      case printerEvents.SELF_CHECKING_STOP:
        console.log("self checking", data);
        event = {
          event: eventType,
          message: "Self Checking Succeed",
        };
        break;

      default:
        event = { event: printerEvents.UNKNOWN_EVENT, message: "job_done" };
        break;
    }

    this.printEvent$.emit({ event, toast: {} });

    if (observer) {
      observer.next(event);
      return observer;
    }
  }

  private onFnFailed(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    error?: any,
    params?: any
  ) {
    const event: IPrinterEvent = {
      event: eventType,
      message: JSON.stringify(error),
      data: params,
    };

    switch (eventType) {
      case printerEvents.PRINT_JOB_FAILED:
        if (params && params.file) {
          params.file.remove(() => {}, this.onFnFailed());
        }
        break;

      default:
        break;
    }

    this.printEvent$.emit({ event, toast: {} });

    if (observer) {
      return observer.next(event);
    }
  }

  private cordovaPlatformRequired(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent>
  ) {
    observer.error({ name: eventType, message: "cordova_platform_required" });
    observer.complete();
    return observer;
  }
}
