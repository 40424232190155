import { Injectable, signal } from "@angular/core";
import { ConnectionStatus, Network } from "@capacitor/network";

@Injectable({
  providedIn: "root",
})
export class NetworkHelperService {
  status = signal<ConnectionStatus | null>(null);

  constructor() {
    this.update();
  }

  async init() {
    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.update();
    });
  }

  private async update() {
    const status = await Network.getStatus();
    this.status.set(status);
  }
}
