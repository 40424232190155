import { inject } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { ModalController, ToastController } from "@ionic/angular";
import { PrinterService } from "@native/printer";
import { forkJoin, tap } from "rxjs";

export const PrinterGuardFunction: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const toastCtrl = inject(ToastController);
  const modalCtrl = inject(ModalController);
  // your  logic goes here

  const printerService = inject(PrinterService);
  return await new Promise(async (resolve) => {
    const toast = await toastCtrl.create({
      message: "Initializing Printers...",
      position: "top",
      color: "warning",
    });

    await toast.present();
    const observe = forkJoin({
      printer: printerService.init(),
    })
      .pipe(
        tap(async (res) => {
          // console.log(res);
        })
      )

      .subscribe({
        next: async (res) => {
          const list = printerService.printers();
          const printer = printerService.printer();

          // if (list.length && !printer) {
          //   printerService.setPrinterById(list[0].id);
          // }

          // console.log(printerService.printer());
          // console.log(printerService.printers());

          // if (res.merchant.mch) {
          //   observe.unsubscribe();

          toast.dismiss();
          //   resolve(true);
          // }
          // if (!res.merchant.mch) {
          //   toast.dismiss();
          //   const modal = await modalCtrl.create({
          //     component: MerchantSelectorComponent,
          //     componentProps: {
          //       autoSelect: false,
          //     },
          //     backdropDismiss: false,
          //   });

          //   await modal.present();

          //   const { data } = await modal.onDidDismiss();
          //   if (data) {
          //     observe.unsubscribe();

          resolve(true);
          //   }
          // }
        },
      });
  });
};
