import { Injectable, signal } from "@angular/core";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { Observable, catchError, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { IPrintingTemplate } from "./interfaces";

@Injectable({
  providedIn: "root",
})
export class ShopApiPrintingLayoutService {
  coreEndPoint = environment.coreEndPoint;
  loading$ = signal(false);

  constructor(private http: HttpHelperService) {}

  getTemplateList<T>(shopId: number): Observable<IHttpResponse<T>> {
    this.loading$.set(true);
    const path = `admin/shop/${shopId}/ticket/templates/search`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading$.set(false))
    );
  }

  getTemplate<T>(
    shopId: number,
    templateId: number
  ): Observable<IHttpResponse<T>> {
    this.loading$.set(true);

    const path = `admin/shop/${shopId}/ticket/template/${templateId}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading$.set(false))
    );
  }

  createTemplate<T>(
    shopId: number,
    data: Partial<IPrintingTemplate>
  ): Observable<IHttpResponse<T>> {
    this.loading$.set(true);

    const path = `admin/shop/${shopId}/ticket/template/add`;
    return this.http.post(path, { data }).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading$.set(false))
    );
  }

  updateTemplate<T>(
    shopId: number,
    templateId: number,
    data: Partial<IPrintingTemplate>
  ): Observable<IHttpResponse<T>> {
    this.loading$.set(true);

    const path = `admin/shop/${shopId}/ticket/template/${templateId}`;
    return this.http.put(path, { data }).pipe(
      catchError(this.http.catch()),
      tap(() => this.loading$.set(false))
    );
  }
}
