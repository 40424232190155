import { Component, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";

import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode/ngx";

import { SplashScreen } from "@capacitor/splash-screen";

import { checkUsbPermission, requestBTPermission } from "./native/printer";

import { trackByIndexFn } from "./helpers/common/functions";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  trackByIndex = trackByIndexFn;

  constructor(
    private platform: Platform,
    private insomnia: Insomnia,
    private backgroundMode: BackgroundMode
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      console.log("Document Ready");

      if (this.platform.is("capacitor")) {
        /**
         * @Splash Hide splash screen
         */
        await SplashScreen.hide();
        /**
         *@TODO   Prevent the screen of the mobile device from falling asleep.
         */
        const awake = await this.insomnia.keepAwake();
        /**
         * @PrinterPermission
         */
        requestBTPermission();
        /**
         * @PrinterPermission
         */
        // checkUsbPermission();

        /**
         *@TODO Enable BackgroundMode
         */
        // this.backgroundMode.setDefaults({
        //   title: "WaiMai Dashboard",
        //   text: "Running in background",
        // });
        // this.backgroundMode.enable();
      }
    });
  }

  ngOnInit(): void {}

  // connect(): void {
  //   this.mqttService.connect({
  //     clientId: `${this.device$()?.platform}_${this.device$()?.uuid}`,
  //   });
  // }
}
