import { EventEmitter, Injectable } from '@angular/core';
import { IPrinterEvent } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class PrintEventService {
  printEvent$ = new EventEmitter<{ event: IPrinterEvent }>();
  constructor() { }
}
