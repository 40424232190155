import { Injectable, inject, signal } from "@angular/core";
import { HttpHelperService } from "@helpers/http";
import {
  injectQuery,
  injectMutation,
  injectQueryClient,
  toPromise,
  injectInfiniteQuery,
} from "@ngneat/query";
import { environment } from "src/environments/environment";
import { TMenuAttributeContentDTO } from "./interfaces";
import * as qs from "qs";
import { Observable, catchError, map } from "rxjs";
import { IHttpResponse } from "@helpers/http/interfaces";

import { AlertController, ToastController } from "@ionic/angular";
@Injectable({
  providedIn: "root",
})
export class MenuAttributeApiService {
  #query = injectQuery();
  #queryInfinite = injectInfiniteQuery();

  #mutation = injectMutation();
  #client = injectQueryClient();
  #http = inject(HttpHelperService);
  #coreEndPoint = environment.coreEndPoint;

  private alertCtrl = inject(AlertController);
  private toastCtrl = inject(ToastController);

  getList(menuId: number, searchQuery = {}) {
    return this.#queryInfinite({
      queryKey: ["attributes", searchQuery],

      queryFn: ({ pageParam, signal }) => {
        const queries = qs.stringify({
          ...searchQuery,
          ...{ skip: pageParam },
        });
        const path = `admin/menu/${menuId}/options?${queries}`;
        return toPromise({
          source: this.#http.get(path).pipe(map((res) => res.data)),
          signal,
        });
      },
      initialPageParam: 0,
      getPreviousPageParam: (firstPage) => null,
      getNextPageParam: (lastPage) => {
        if (!lastPage) return null;
        const { count, skip, limit } = lastPage;
        if (count <= skip + limit) return null;
        return lastPage.skip + lastPage.limit;
      },
    });
  }

  getById(menuId: number, id: number) {
    return this.#query({
      queryKey: ["attribute"] as const,
      queryFn: () => {
        const path = `admin/menu/${menuId}/option/${id}`;
        return this.#http.get(path).pipe(map((res) => res.data));
      },
    });
  }

  addAttribute<T>(
    mid: number,
    body: { data: TMenuAttributeContentDTO }
  ): Observable<IHttpResponse<T>> {
    const path = `admin/menu/${mid}/option/add`;

    return this.#http.post(path, body).pipe(catchError(this.#http.catch()));
  }

  removeAttribute() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Attribute deleted !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid }) => {
        const path = `admin/menu/${mid}/option/${optid}`;

        return this.#http.delete(path).pipe(map((res) => res.data));
      },
    });
  }

  updateStatus<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, status }) => {
        const path = `admin/menu/${mid}/option/${optid}/status`;
        return this.#http.put(path, { status }).pipe(map((res) => res.data));
      },
    });
  }

  updateMandatory<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, mandatory }) => {
        const path = `admin/menu/${mid}/option/${optid}/mandatory`;
        return this.#http.put(path, { mandatory }).pipe(map((res) => res.data));
      },
    });
  }

  updateContent<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Content Updated !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, body }) => {
        const path = `admin/menu/${mid}/option/${optid}/content`;
        return this.#http.put(path, body).pipe(map((res) => res.data));
      },
    });
  }

  updateRelatedItemsPosition() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Item orders Updated !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, body }) => {
        const path = `admin/menu/${mid}/option/${optid}/related/items/position`;

        return this.#http.put(path, body).pipe(map((res) => res.data));
      },
    });
  }

  addRelatedItem() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Item created !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, body }) => {
        const path = `admin/menu/${mid}/option/${optid}/related/items/add`;

        return this.#http.post(path, body).pipe(map((res) => res.data));
      },
    });
  }

  removeRelatedItem() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Item deleted !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, itemId }) => {
        const path = `admin/menu/${mid}/option/${optid}/related/items/${itemId}`;

        return this.#http.delete(path).pipe(map((res) => res.data));
      },
    });
  }

  updateRelatedItem() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Item updated !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, optid, itemId, body }) => {
        const path = `admin/menu/${mid}/option/${optid}/related/items/${itemId}`;

        return this.#http.put(path, body).pipe(map((res) => res.data));
      },
    });
  }

  errorHandler = async (error: any, variables, context) => {
    if (typeof error === "string") {
      const alert = await this.alertCtrl.create({
        header: `Error`,
        // subHeader: "Error",
        message: error,
        buttons: ["OK"],
      });

      alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        header: error.name,
        subHeader: error.message,
        message: error.problems ? error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }

    if (error.error instanceof ProgressEvent) {
      const alert = await this.alertCtrl.create({
        header: `ProgressEvent Error`,
        // subHeader: "Error",
        message: "Please try later",
        buttons: ["OK"],
      });

      alert.present();
    } else if (error.error) {
      const alert = await this.alertCtrl.create({
        header: error.error.name,
        subHeader: error.error.message,
        message: error.error.problems ? error.error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }
  };

  async presentToast(
    message: string,
    color: "danger" | "success" | "warning" = "success"
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
          handler: () => {
            console.log("Dismiss clicked");
          },
        },
      ],
      position: "top",
      positionAnchor: "header",
      color,
      swipeGesture: "vertical",
    });

    await toast.present();
  }
}
