import { IMenuCategory } from "../interfaces";

export const menuCategoryListAddItem = (list: IMenuCategory[], categories: IMenuCategory[]): IMenuCategory[] => {
    categories.forEach(c => {
        const idx = list.findIndex(item => item.id === c.id);
        if (idx < 0) {
            list.push(c);
        }

        if (idx >= 0) {
            list[idx] = c;
        }

    });
    return list;
};


export const menuCategoryFindById = (list: IMenuCategory[], cid: number, token?: string): IMenuCategory | null => {
    let category = null;
    if (token) {
        const idx = list.findIndex(item => item.id === cid && item.token && token);
        if (idx >= 0) {
            category = list[idx];
        }
    }

    if (!token) {
        const idx = list.findIndex(item => item.id === cid);
        if (idx >= 0) {
            category = list[idx];
        }
    }

    return category;

};
