import { Injectable, signal } from "@angular/core";
import * as CordovaDevice from "@awesome-cordova-plugins/device";
import { Device } from "@capacitor/device";
import {
  AlertController,
  LoadingController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { IDeviceInfo } from "./interfaces";
import { App } from "@capacitor/app";
import { environment } from "src/environments/environment";
import ApkUpdater from "cordova-plugin-apkupdater";
@Injectable({
  providedIn: "root",
})
export class DeviceHelperService {
  device$ = signal<IDeviceInfo | null>(null);
  private isNative = this.platform.is("capacitor");
  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private loadCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {}

  async init() {
    const cordovaDevice = CordovaDevice.Device;
    // Dev
    const info = await Device.getInfo();
    const id = await Device.getId();

    this.device$.set({
      ...info,
      ...{ uuid: id.identifier },
      ...{
        version: info.osVersion,
        serialNumber: this.isNative ? cordovaDevice.serial : "web",
        appVersion: this.isNative
          ? (await App.getInfo()).version
          : environment.appVersion,
      },
    });

    return this.device$();
  }

  async update() {
    if (this.isNative) {
      let alert = await this.alertCtrl.create({
        header: "Update",
        message: `Do you want to check app's update?`,
        mode: "ios",
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Yes",
            role: "confirm",
            handler: () => {},
          },
        ],
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role === "confirm") {
        const loader = await this.loadCtrl.create({
          message: "Checking....",
          spinner: "circles",
        });
        await loader.present();

        await ApkUpdater.download(
          environment.apkUrl,
          {
            onDownloadProgress: ({ progress }) => {
              loader.message = `${Math.floor(progress)}% Downloading`;
            },
            onUnzipProgress: console.log,
          },
          async (res) => {
            console.log(res);
            loader.message = "Unzipping...";

            await ApkUpdater.install(
              async (data) => {
                await loader.dismiss();
                console.log("install success", data);
                await this.presentToast("Success", "success");
              },
              async (e) => {
                console.log("install failed", e);
                await this.presentToast("Install Failed", "danger");
                await loader.dismiss();
              }
            );
          },
          async (e) => {
            console.log(e?.message);
            console.log(e?.stack);

            await this.presentToast(e.message, "danger");
            await loader.dismiss();
          }
        );
      }
    }
  }

  private async presentToast(
    message: string,
    color: "danger" | "success" | "warning" = "success"
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
          handler: () => {
            console.log("Dismiss clicked");
          },
        },
      ],
      position: "top",
      positionAnchor: "header",
      color,
      swipeGesture: "vertical",
    });

    await toast.present();
  }
}
