import { inject } from "@angular/core";
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { MerchantApiService } from "@api/merchant";
import { MerchantSelectorComponent } from "@components/merchant/merchant-selector";
import {
  MenuController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { forkJoin, tap } from "rxjs";

export const MerchantGuardFunction: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const menuCtrl = inject(MenuController);
  const toastCtrl = inject(ToastController);
  const modalCtrl = inject(ModalController);
  // your  logic goes here

  const merchantApiService = inject(MerchantApiService);
  return await new Promise(async (resolve) => {
    const toast = await toastCtrl.create({
      message: "Initializing Merchant...",
      position: "top",
      color: "warning",
    });

    await toast.present();
    const observe = forkJoin({
      merchant: merchantApiService.init(),
    })
      .pipe()

      .subscribe({
        next: async (res) => {
          if (res.merchant.mch) {
            observe.unsubscribe();
            menuCtrl.enable(true);
            toast.dismiss();
            resolve(true);
          }
          if (!res.merchant.mch) {
            toast.dismiss();
            const modal = await modalCtrl.create({
              component: MerchantSelectorComponent,
              componentProps: {
                autoSelect: false,
              },
              backdropDismiss: false,
            });

            await modal.present();

            const { data } = await modal.onDidDismiss();
            if (data) {
              observe.unsubscribe();
              menuCtrl.enable(true);
              resolve(true);
            }
          }
        },
      });
  });
};
